<template>
  <div v-if="items.length || showEmpty">
    <h5 class="mb-0 text-pp">หวยชุด</h5>
    <div v-if="items.length" class="row rounds no-gutters">
      <div class="col-md-4 mb-2" v-for="(round) in items" :key="round._id">
        <SetsRound :data="round" @reload="getRounds" />
      </div>
    </div>
    <div v-else-if="showEmpty" class="alert alert-warning border-warning mt-2">
      ไม่มีข้อมูลเปิดรับแทง
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import cAlert from '@/helpers/alert'
import LotteryService from '@/services/LotteryService'
import SetsRound from './SetsRound'
import moment from '@/helpers/moment'

export default {
  name: 'SetsRounds',
  components: {
    SetsRound
  },
  props: {
    showEmpty: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: this.$store.state.lottosetRounds,
      isLoading: false
    }
  },
  computed: {
    items() {
      return _.orderBy((this.data || []), (round)=>{
        const diff = moment(round.roundDate.close).diff(moment(), 's')
        if(diff > 0) {
          return diff
        }else{
          return 10000000 + diff
        }
      }, ['asc'])
    }
  },
  methods: {
    getRounds(silent=false) {
      this.isLoading = !silent
      LotteryService.getLottosetRounds()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$store.commit('updateLottosetRounds', this.data)
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getRounds(this.data?true:false)
  }
}
</script>
