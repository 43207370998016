<template>
  <span>{{status}}</span>
</template>
<script>
import moment from '@/helpers/moment'
export default {
  name: 'RoundStatus',
  props: ['data'],
  data() {
    return {
      status: null,
      needReload: false,
      statusCode: null
    }
  },
  watch: {
    statusCode(n, o) {
      if(o) {
        this.$emit('changeStatus', this.needReload)
      }
    }
  },
  methods: {
    statusCountdown() {
      if(this.data.status === 'Open') {

        if(moment().isBetween(this.data.roundDate.open, this.data.roundDate.close)) {
          this.needReload = true
          const diff = moment(this.data.roundDate.close).diff(moment(), 'milliseconds')
          const duration = moment.duration(diff, 'milliseconds')
          let d = moment.duration(duration).days(),
              h = moment.duration(duration).hours(),
              m = moment.duration(duration).minutes(),
              s = moment.duration(duration).seconds()

          if(d > 0) h = (d*24) + h

          const texts = []
          if(h > 0) {
            if(h < 10)
              texts.push(`0${h}`)
            else
              texts.push(h)
          }

          if(h > 0 || m > 0) {
            if(m < 10)
              texts.push(`0${m}`)
            else
              texts.push(m)
          }

          if(s < 10)
            texts.push(`0${s}`)
          else
            texts.push(s)

          this.status = `ปิดรับใน ${texts.join(':')}`
          this.$emit('cssStyle', {
            bg: 'round-bg-success',
            text: 'round-text-success'
          })
          this.statusCode = 'openning'
        }else
        if(moment().isBefore(this.data.roundDate.open)) {
          this.needReload = true
          const diff = moment(this.data.roundDate.open).diff(moment(), 'milliseconds')
          const duration = moment.duration(diff, 'milliseconds')
          let d = moment.duration(duration).days(),
              h = moment.duration(duration).hours(),
              m = moment.duration(duration).minutes(),
              s = moment.duration(duration).seconds()

          if(d > 0) h = (d*24) + h

          const texts = []
          if(h > 0) {
            if(h < 10)
              texts.push(`0${h}`)
            else
              texts.push(h)
          }

          if(h > 0 || m > 0) {
            if(m < 10)
              texts.push(`0${m}`)
            else
              texts.push(m)
          }

          if(s < 10)
            texts.push(`0${s}`)
          else
            texts.push(s)

          this.status = `เปิดในอีก ${texts.join(':')}`
          this.$emit('cssStyle', {
            bg: 'round-bg-warning',
            text: 'round-text-warning'
          })
          this.statusCode = 'wait-to-open'
        }else{
          clearInterval(this.Interval)
          this.status = 'ปิดรับ'
          this.$emit('cssStyle', {
            bg: 'alert-secondary border-secondary',
            text: 'text-dark'
          })
          this.statusCode = 'close'
        }
      }else
      if(this.data.status === 'Refund'){
        this.status = 'ยกเลิก'
        this.$emit('cssStyle', {
          bg: 'round-bg-danger',
          text: 'round-text-danger',
          link: 'text-danger',
          btn: 'text-danger'
        })
      }else
      if(this.data.status === 'Complete'){
        this.status = 'กำลังออกผล'
        this.$emit('cssStyle', {
          bg: 'round-bg-info',
          text: 'round-text-info',
          link: 'text-success',
          btn: 'text-secondary'
        })
      }else
      if(this.data.status === 'Paid'){
        this.status = 'จ่ายเงินแล้ว'
        this.$emit('cssStyle', {
          bg: 'round-bg-primary',
          text: 'round-text-primary',
          link: 'text-primary',
          btn: 'text-primary'
        })
      }else{
        clearInterval(this.Interval)
        this.status = this.data.status
        this.statusCode = 'close'
      }
    }
  },
  mounted() {
    this.statusCountdown()
    clearInterval(this.Interval)
    this.Interval = setInterval(() => {
      this.statusCountdown()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  }
}
</script>
